import { graphql, useStaticQuery } from 'gatsby';

export const BlogListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpBlogs {
      posts: allWpPost(sort: { fields: [date], order: [DESC] }) {
        edges {
          node {
            categories {
              nodes {
                posts {
                  nodes {
                    uri
                    title
                    featuredImage {
                      node {
                        altText
                        mediaItemUrl
                        uri
                      }
                    }
                  }
                }
                name
                count
              }
            }
            title
            uri
            excerpt
            featuredImage {
              node {
                altText
                mediaItemUrl
                title
                uri
              }
            }
          }
        }
      }
      category: allWpCategory {
        edges {
          node {
            name
            count
            uri
          }
        }
      }
    }
  `);
  return data;
};
