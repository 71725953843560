import React from "react";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";
const GeneralContent = ({ module }) => {
  return (
    <>
      <section  className="short-content text-center lg:py-60 py-30 lg:mt-60 mt-30">
        <div className="container m-auto">
          <div className=" w-full m-auto fade-ani">
            <div className="title-brown">
              <h2>{module.heading}</h2>
            </div>
            <div className="content lg:px-80">{parse(module.description)}</div>
            <div className="btn-custom mt-30">
              {module.button &&
                <Link
                  to={module.button.url}
                  target={module.button.target}
                  className="button button-tan"
                >
                  {module.button.title}
                </Link>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeneralContent;
export const GeneralContentFragment = graphql`
  fragment GeneralContentFragment on WpPage_Acfpagecontent_PageContent_GeneralContent {
    # content
    id
    heading
    extraClass
    description
    button {
      url
      title
      target
    }
  }
`;
