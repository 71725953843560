import { graphql, useStaticQuery } from 'gatsby';

export const OfferListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpPosts {
      allWpOffer(sort: { fields: [menuOrder], order: [ASC] }) {
        nodes {
          menuOrder
          excerpt
          title
          link
          featuredImage {
            node {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  `);
  return data;
};
