import React,{useState} from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";

const ContentWithPdf = ({ module, i }) => {
  const [tabOption2, setTabOption2] = useState('current-year');
  return (
    <>
      <section className="rate-detail relative lg:py-60 py-30 lg:mt-60 mt-30">
        <div className="container-fluid">
          <div className="lg:w-6/12 w-full m-auto text-center">
            <div className="title-brown">
              <h2>{module.title}</h2>
            </div>
            <div className="content mt-0 max-w-400">
              {parse(module.description)}
            </div>
            <div className="btn-custom lg:mt-25 mt-10">
              <Link to={module.uploadFile.mediaItemUrl} target="_blank" className="button button-tan">
                {module.uploadFile.title}
              </Link>
            </div>
            <ul className="tabs flex justify-center space-x-5 pt-50">
              <li
                onClick={() => {
                  setTabOption2("current-year");
                }}
                className={`tab-link cursor-pointer text-brown capitalize font-400 text-18 ${
                  tabOption2 === "current-year" ? "tab-current" : ""
                }`}
                data-tab="tab-1"
              >
                2022
              </li>
              <li
                onClick={() => {
                  setTabOption2("next-year");
                }}
                className={`tab-link cursor-pointer text-brown capitalize font-400 text-18 ${
                  tabOption2 === "next-year" ? "tab-current" : ""
                }`}
                data-tab="tab-2"
              >
                2023
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentWithPdf;

export const ContentWithPdfFragment = graphql`
  fragment ContentWithPdfFragment on WpPage_Acfpagecontent_PageContent_ContentWithPdf {
    # content
    id
    title
    extraClass
    description
    uploadFile {
      altText
      uri
      title
      mediaItemUrl
    }
  }
`;
