import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const FeaturedBlog = ({ module, i }) => {
  return (
    <>
      <section
        key={i}
        className="zigzag portrait lg:py-60 py-30 lg:mt-60 mt-30"
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <span>Featured Blog </span>
                <div className="title-black">
                  <h2>{module.featureBlog.title}</h2>
                </div>
                <div className="content">
                  {parse(module.featureBlog.excerpt)}
                </div>
                <div className="btn-custom lg:mt-40 mt-20">
                  <Link
                    to={module.featureBlog.uri}
                    className="button button-tan"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              {module.featureBlog.featuredImage.node && (
                <ImageOpt
                  imgData={module.featureBlog.featuredImage.node?.mediaItemUrl}
                  imgAlt="featured blog"
                  imgLoad="lazy"
                  width={645}
                  height={574}
                  imgClass="block object-cover w-full"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedBlog;
export const FeaturedBlogFragment = graphql`
  fragment FeaturedBlogFragment on WpPage_Acfpagecontent_PageContent_FeaturedBlog {
    id
    featureBlog {
      ... on WpPost {
        id
        excerpt
        title
        uri
        featuredImage {
          node {
            altText
            mediaItemUrl
            title
          }
        }
      }
    }
  }
`;
