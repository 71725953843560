import { Link, graphql } from 'gatsby';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const Banner = ({ module, i }) => {
  return (
    <>
      <section className="banner h-screen relative">
        <div className="banner-img h-full flex items-end justify-center">
          <ImageOpt
            imgData={module?.backgroundImage.mediaItemUrl}
            imgAlt="banner"
            imgLoad="eager"
            width={1440}
            height={856}
            imgFetchPriority="high"
            imgClass="banner-img h-full w-full object-cover flex items-end justify-center"
          />
        </div>
        <div className="banner-info z-[5] absolute bottom-60 text-center lg:pb-50 pb-50 w-full  fade-ani">
          <div className="flex justify-center lg:space-x-10 space-x-5 banner-info-link">
            {module.preHeadings &&
              module.preHeadings.map((item, i) => {
                return (
                  <h6 className="font-body tracking-018em text-14 text-white relative uppercase">
                    {item.preHeading}
                  </h6>
                );
              })}
          </div>
          <div className="banner-title mt-30">
            <h1 className="text-white tracking-004em">{module.heading}</h1>
            {module.button && (
              <div className="btn-custom mt-40">
                <Link
                  to={module.button.url}
                  className="button button-brown-border"
                >
                  {module.button.title}
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

export const BannerFragment = graphql`
  fragment BannerFragment on WpPage_Acfpagecontent_PageContent_Banner {
    # content
    id
    heading
    fullVideo
    fieldGroupName
    backgroundImage {
      altText
      uri
      mediaItemUrl
    }
    button {
      target
      title
      url
    }
    preHeadings {
      preHeading
    }
    extraClass
    backgroundVideo
  }
`;
