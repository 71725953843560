import { graphql, useStaticQuery } from 'gatsby';
export const ExperiencesListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpExperience {
      allWpExperience(sort: { fields: [menuOrder], order: [ASC] }) {
        edges {
          node {
            id
            title
            menuOrder
            excerpt
            content
            experienceContent {
              gallery {
                mediaItemUrl
              }
            }
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `);
  return data;
};
