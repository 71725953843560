import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import Modal from 'react-modal';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageOpt from '../ImageOpt.js';
import { ExperiencesListingData } from './ExperiencesListingData';

const ExperiencesListing = () => {
  const experiencesData = ExperiencesListingData();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [url, setURL] = React.useState([]);
  const [title, setTitle] = React.useState(null);
  const [content, setContent] = React.useState(null);

  function openModal(data) {
    for (var i = 0; i < experiencesData.allWpExperience.edges.length; i++) {
      if (experiencesData.allWpExperience.edges[i].node.id === data) {
        setURL(
          experiencesData.allWpExperience.edges[i].node.experienceContent
            .gallery
        );
        setTitle(experiencesData.allWpExperience.edges[i].node.title);
        setContent(
          parse(experiencesData.allWpExperience.edges[i].node.content)
        );
      }
    }
    document.body.classList.add('modal-open');
    setIsOpen(true);
  }

  const customStyles = {
    content: {
      // backgroundColor: "#FBF7F6",
      // border: "0",
      // borderRadius: "0",
      // insent: "0px",
      // padding: "0px",
      // zIndex: "99",
      // height: "100vh",
      // width: "100%",

      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      top: '0',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      // transform: "translate(-50%, -50%)",
      padding: '0',
      border: '0',
      borderRadius: '0',
      height: '100vh',
      width: '100%',
    },
  };

  function closeModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  }

  return (
    <>
      <section className="explore-card lg:py-60 py-30 imgSm">
        <div className="container-fluid">
          <div className="flex flex-wrap mx-minus-10">
            {experiencesData.allWpExperience.edges.length > 0 &&
              experiencesData.allWpExperience.edges.map((item, i) => {
                return (
                  <div className="xl:w-4/12 md:w-4/12 px-10 w-full">
                    <div className="card-bx">
                      <div
                        className=" cursor-pointer"
                        onClick={() => {
                          openModal(item.node.id);
                        }}
                      >
                        <ImageOpt
                          imgData={item.node.featuredImage.node?.mediaItemUrl}
                          imgAlt={item.node.featuredImage.node.altText}
                          imgLoad="lazy"
                          width={417}
                          height={323}
                          imgClass="object-cover w-full h-full"
                        />
                      </div>
                      <div className="content fade-ani pt-30">
                        <h4
                          className="capitalize  cursor-pointer"
                          onClick={() => {
                            openModal(item.node.id);
                          }}
                        >
                          {item.node.title}
                        </h4>
                        {parse(item.node.excerpt)}
                        <button
                          onClick={() => {
                            openModal(item.node.id);
                          }}
                          className="link-btn mt-15 pointer"
                        >
                          <span></span>Explore
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="img-modal img-modal-popup">
          <button
            className="modal-close absolute right-20 top-20"
            onClick={closeModal}
          >
            <img src="../images/modal-close.svg" loading="lazy" alt="close" />
          </button>
          <div className="flex flex-wrap">
            <div className="lg:w-6/12 zigzag w-full">
              <div className="img-slider relative">
                <Swiper
                  speed={1000}
                  effect={'fade'}
                  loop={true}
                  navigation={true}
                  autoplay={{
                    delay: 3000,
                  }}
                  modules={[Navigation, Autoplay, Pagination, EffectFade]}
                >
                  {url.map((item, i) => {
                    return (
                      <div key={i}>
                        <SwiperSlide>
                          {item && (
                            <ImageOpt
                              imgData={item?.mediaItemUrl}
                              imgAlt={item.altText}
                              imgLoad="lazy"
                              width={576}
                              height={692}
                              imgClass="object-cover w-full h-full"
                            />
                          )}
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="h-full pop-content flex flex-col items-start lg:py-50 py-30 lg:px-50 px-30 text-left">
                <span className="text-tan tracking-004em text-14 uppercase font-body font-400 mb-10">
                  Experiences
                </span>
                <div className="title-black">
                  <h2>{title}</h2>
                </div>
                <div className="content">{content}</div>
                <div className="btn-custom mt-15">
                  <Link to="/traveler-enquiry" className="button button-tan">
                    Make an enquiry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExperiencesListing;
export const ExperiencesListingFragment = graphql`
  fragment ExperiencesListingFragment on WpPage_Acfpagecontent_PageContent_ExperiencesListing {
    # content
    id
    extraClass
  }
`;
