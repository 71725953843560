import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ImageOpt from '../ImageOpt.js';
import { BlogListingData } from './BlogListingData';

const BlogListing = () => {
  const blogData = BlogListingData();
  const categories = BlogListingData().category.edges;
  const blogs = blogData.posts.edges;
  const [tabStatus, setTabStatus] = useState('all');
  const [tabDetails, setTabDetails] = useState(blogs);

  useEffect(() => {
    if (tabStatus !== 'all') {
      const data = [];
      for (var i = 0; i < blogs.length; i++) {
        for (var j = 0; j < blogs[i].node.categories.nodes.length; j++) {
          if (blogs[i].node.categories.nodes[j].name === tabStatus) {
            data.push(blogs[i]);
          }
        }
      }
      setTabDetails(data);
    } else {
      setTabDetails(blogs);
    }
  }, [tabStatus]);

  return (
    <>
      <section className="explore-card lg:py-60 py-30 explode-blog-card imgSm">
        <div className="container-fluid">
          <div className="imgGrid-tabs lg:pt-60 pt-30">
            <ul className="lg:space-x-10 flex flex-wrap lg:pt-0 pt-50">
              <li className={`${'all' === tabStatus ? 'active-tab' : ''}`}>
                <a
                  className="text-brown text-18 font-400 hover:text-tandark cursor-pointer"
                  onClick={() => {
                    setTabStatus('all');
                  }}
                >
                  All
                </a>
              </li>
              {categories.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`${
                      item.node.name === tabStatus ? 'active-tab' : ''
                    }`}
                  >
                    <a
                      className="text-brown text-18 font-400 hover:text-tandark cursor-pointer"
                      onClick={() => {
                        setTabStatus(item.node.name);
                      }}
                      to="/"
                    >
                      {item.node.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex flex-wrap mx-minus-10">
            {tabDetails.length > 0 &&
              tabDetails.map((item, i) => {
                return (
                  <div key={i} className="xl:w-4/12 md:w-4/12 px-10 w-full">
                    <div className="card-bx">
                      {item.node.featuredImage && (
                        <Link to={item.node.uri}>
                          <ImageOpt
                            imgData={item.node.featuredImage.node?.mediaItemUrl}
                            imgAlt={item.node.featuredImage.node.altText}
                            imgLoad="lazy"
                            width={417}
                            height={323}
                            imgClass="object-cover w-full h-full"
                          />
                        </Link>
                      )}
                      <div className="content fade-ani pt-30">
                        <Link to={item.node.uri}>
                          <h4 className="capitalize">{item.node.title}</h4>
                        </Link>
                        {parse(item.node.excerpt)}
                        <div className="link-btn mt-25">
                          <span></span>
                          <Link to={item.node.uri}>Read More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {tabDetails.length == 0 && (
              <div className="text-center lg:mt-60 mt-30 w-full">
                <p className="text-brown"> Not Found </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogListing;

export const BlogListingFragment = graphql`
  fragment BlogListingFragment on WpPage_Acfpagecontent_PageContent_BlogListing {
    # content
    id
    fieldGroupName
    extraClass
  }
`;
