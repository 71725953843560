import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import ImageOpt from '../ImageOpt.js';

const GalleryTabs = ({ module }) => {
  const imageFixLimit = 4;
  const [gallery, setGallery] = useState([]);
  const [sliderGallery, setsliderGallery] = useState([]);
  const [tabOption, setTabOption] = useState('all');
  const [imageLimit, setImageLimit] = useState(imageFixLimit);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [isVideoType, setisVideoType] = useState('ImageVideo');
  const [videoUrl, setVideoUrl] = useState(false);
  useEffect(() => {
    let temp_gallery = [];
    let all_gallery = [];
    let t = 0;
    let v = 0;
    for (var i = 0; i < module.galleryTabs.length; i++) {
      for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
        if (
          isVideoType === 'ImageVideo' &&
          (tabOption === module.galleryTabs[i].heading || tabOption === 'all')
        ) {
          all_gallery[v] = {
            image: module.galleryTabs[i].galleries[j].image,
            video: module.galleryTabs[i].galleries[j].video,
            heading: module.galleryTabs[i].heading,
          };
          v++;
          if (temp_gallery.length < imageLimit) {
            temp_gallery[t] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
              heading: module.galleryTabs[i].heading,
            };
            t++;
          } else {
            break;
          }
        } else if (
          isVideoType === 'Image' &&
          (tabOption === module.galleryTabs[i].heading || tabOption === 'all')
        ) {
          if (
            module.galleryTabs[i].galleries[j].image &&
            module.galleryTabs[i].galleries[j].video == null
          ) {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
              heading: module.galleryTabs[i].heading,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
                heading: module.galleryTabs[i].heading,
              };
              t++;
            } else {
              break;
            }
          }
        } else if (
          isVideoType === 'Video' &&
          (tabOption === module.galleryTabs[i].heading || tabOption === 'all')
        ) {
          if (
            module.galleryTabs[i].galleries[j].image &&
            module.galleryTabs[i].galleries[j].video
          ) {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
              heading: module.galleryTabs[i].heading,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
                heading: module.galleryTabs[i].heading,
              };
              t++;
            } else {
              break;
            }
          }
        }
      }
    }
    setsliderGallery(() => [...all_gallery]);
    setGallery(() => [...temp_gallery]);
  }, [tabOption, imageLimit, isVideoType]);

  const customStylesVideo = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      padding: '0',
    },
  };

  const [isOpenVideo, setIsOpenVideo] = React.useState(false);

  function closeModalVideo() {
    setIsOpenVideo(false);
  }

  function filterData(title) {
    let temp_gallery = [];
    let all_gallery = [];
    let t = 0;
    let v = 0;
    if (title === 'ImageVideo') {
      let temp_gallery = [];
      let all_gallery = [];
      let t = 0;
      let v = 0;
      for (var i = 0; i < module.galleryTabs.length; i++) {
        for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
          all_gallery[v] = {
            image: module.galleryTabs[i].galleries[j].image,
            video: module.galleryTabs[i].galleries[j].video,
          };
          v++;
          if (temp_gallery.length < imageLimit) {
            temp_gallery[t] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
            };
            t++;
          } else {
            break;
          }
        }
      }
    } else if (title === 'Image') {
      for (var i = 0; i < module.galleryTabs.length; i++) {
        for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
          if (module.galleryTabs[i].galleries[j].video === '') {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
              };
              t++;
            } else {
              break;
            }
          }
        }
      }
    } else if (title === 'Video') {
      for (var i = 0; i < module.galleryTabs.length; i++) {
        for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
          if (module.galleryTabs[i].galleries[j].video !== '') {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
              };
              t++;
            } else {
              break;
            }
          }
        }
      }
    }
    setsliderGallery(() => [...all_gallery]);
    setGallery(() => [...temp_gallery]);
  }

  return (
    <>
      <section className="img-grid lg:py-60 py-30 lg:mt-60 mt-30">
        <div className="container-fluid">
          <div className="img-grid-filter flex flex-wrap justify-between lg:pb-100 pb-60">
            <div className="imgGrid-tabs lg:mb-0 mb-20">
              <ul className="lg:space-x-10 flex flex-wrap">
                <li
                  onClick={() => {
                    setTabOption('all');
                    setImageLimit(imageFixLimit);
                    setisVideoType('ImageVideo');
                  }}
                  className={`tab-link cursor-pointer text-black2  text-15 tracking-05em ${
                    tabOption === 'all' ? 'active-tab' : ''
                  }`}
                  data-tab="all"
                >
                  <a
                    to="/"
                    className="text-brown text-18 font-400 hover:text-tandark"
                  >
                    All
                  </a>
                </li>
                {module.galleryTabs.map((item, i) => {
                  return (
                    <li
                      className={`${
                        tabOption == item.heading ? 'active-tab' : ''
                      }`}
                    >
                      <a
                        onClick={() => {
                          setTabOption(item.heading);
                          setImageLimit(imageFixLimit);
                          setisVideoType('ImageVideo');
                        }}
                        className={`text-brown cursor-pointer text-18 font-400 hover:text-tandark ${
                          tabOption === item.heading ? 'tab-current' : ''
                        }`}
                        data-tab={item.heading}
                      >
                        {item.heading}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className="tab-filter"
              onChange={(e) => {
                setisVideoType(e.target.value);
              }}
            >
              <select
                name=""
                id=""
                onChange={(e) => {
                  setImageLimit(imageFixLimit);
                  filterData(e.target.value);
                }}
              >
                <option
                  value="ImageVideo"
                  selected={isVideoType === 'ImageVideo' ? 'selected' : ''}
                >
                  Image & Video
                </option>
                <option
                  value="Image"
                  selected={isVideoType === 'image' ? 'selected' : ''}
                >
                  Image
                </option>
                <option
                  value="Video"
                  selected={isVideoType === 'video' ? 'selected' : ''}
                >
                  Video
                </option>
              </select>
            </div>
          </div>

          <div className="flex flex-wrap lg:m-minus-15 m-0">
            {gallery.map((item, i) => {
              return (
                <div
                  key={i}
                  className="lg:w-6/12 w-full lg:px-15 px-0 lg:mb-30 mb-20"
                >
                  <div className="img-grid-row relative">
                    <div className="img">
                      {item.image && (
                        <>
                          <div
                            onClick={() => {
                              setPhotoIndex(i);
                              setisOpen(true);
                            }}
                          >
                            <ImageOpt
                              imgData={item.image.mediaItemUrl}
                              imgAlt={item.title}
                              imgLoad="lazy"
                              width={630}
                              height={437}
                              imgClass="object-cover h-full w-full"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {item.video !== null && (
                      <div className="img-grid-video absolute top-50-per left-50-per translate-x-minus_50 translate-y-minus_50 z-9">
                        <button
                          onClick={() => {
                            setIsOpenVideo(true);
                            setVideoUrl(item.video);
                          }}
                          className="inline-block play-icon"
                        >
                          <img
                            src="../images/play-icon.svg"
                            loading="lazy"
                            alt=""
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {imageLimit < sliderGallery.length ? (
            <div className="text-center cursor-pointer lg:mt-60 mt-30">
              <a
                onClick={() => {
                  setImageLimit((imageLimit) => imageLimit + imageFixLimit);
                }}
                className="button button-tan"
              >
                Load More
              </a>
            </div>
          ) : (
            <p className="text-brown">Not Found.</p>
          )}
        </div>
      </section>
      {isOpenVideo && (
        <Modal
          isOpen={isOpenVideo}
          onRequestClose={closeModalVideo}
          style={customStylesVideo}
        >
          <div className="video-modal-popup ">
            <button onClick={closeModalVideo} className="pr-30 pt-30">
              <img src="../images/modal-close.svg" loading="lazy" alt="" />
            </button>
            <div className="modal-video z-0">
              <ReactPlayer
                className="modal-banner-video"
                loop={true}
                playing={true}
                styleVideo={customStylesVideo}
                controls={true}
                muted={true}
                url={videoUrl}
              />
            </div>
          </div>
        </Modal>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={sliderGallery[photoIndex].image.mediaItemUrl}
          nextSrc={sliderGallery[(photoIndex + 1) % sliderGallery.length]}
          onImageLoadError={() => {}}
          imageLoadErrorMessage={() => {}}
          prevSrc={
            sliderGallery[
              (photoIndex + sliderGallery.length - 1) % sliderGallery.length
            ]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + sliderGallery.length - 1) % sliderGallery.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % sliderGallery.length
            )
          }
        />
      )}
    </>
  );
};

export default GalleryTabs;
export const GalleryTabsFragment = graphql`
  fragment GalleryTabsFragment on WpPage_Acfpagecontent_PageContent_GalleryTabs {
    galleryTabs {
      heading
      galleries {
        video
        image {
          altText
          mediaItemUrl
          uri
          title
        }
      }
    }
  }
`;
