import React from "react";
import { graphql } from "gatsby";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import AccommodationListingGrid from "../Modules/AccommodationListingGrid";
import AccommodationListingSlider from "../Modules/AccommodationListingSlider";

const AccommodationListing = ({ module }) => {
  return (
    <>
      {module.listingStyle === "grid" ? (
        <AccommodationListingGrid module={module} />
      ) : (
        <AccommodationListingSlider module={module} />
      )}
    </>
  );
};

export default AccommodationListing;
export const AccommodationListingFragment = graphql`
  fragment AccommodationListingFragment on WpPage_Acfpagecontent_PageContent_AccommodationListing {
    # content
    id
    heading
    listingStyle
    fieldGroupName
    extraClass
  }
`;
