import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const SimpleContent = ({ module }) => {
  return (
    <section className="common-content general-content lg:py-60 py-30">
      <div className="container-fluid">
        <div className="common-content-inner">
          <div className="title-black">
            <h2>{module.heading}</h2>
          </div>
          <div className="content global-list">{parse(module.content)}</div>
        </div>
      </div>
    </section>
  );
};

export default SimpleContent;

export const SimpleContentFragment = graphql`
  fragment SimpleContentFragment on WpPage_Acfpagecontent_PageContent_SimpleContent {
    # content
    id
    content
    extraClass
    fieldGroupName
  }
`;
