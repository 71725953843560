import { Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';
import { BlogListingDataLimit } from '../Modules/BlogListingDataLimit';

const ExploreBlog = () => {
  const blog_data = BlogListingDataLimit();
  const blog = blog_data.posts.edges;
  return (
    <>
      <section className="blog lg:py-60 py-30">
        <div className="container-fluid">
          <div className="blog-top fade-ani">
            <div className="lg:w-8/12 w-full">
              <span className="text-tan text-14 tracking-004em font-400 uppercase font-body">
                Blog
              </span>
              <div className="title-black mt-10">
                <h2>Explore stories from Sanbona’s plains</h2>
              </div>
              <div className="content">
                <p className="m-0">
                  Find out what's been happening in and around Sanbona, our
                  conservation success stories, and more in our blog.
                </p>
              </div>
              <div className="link-btn mt-30">
                <span></span>
                <a href="/blog">Explore our blog posts </a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-grid">
          <div className="container-fluid">
            <div className="flex flex-wrap lg:mx-minus-15">
              {blog.map((item, i) => {
                return (
                  <div className="lg:w-4/12 w-full lg:mt-50 mt-20 lg:px-15">
                    <div className="blog-bx">
                      {item.node.featuredImage && (
                        <Link to={item.node.uri} className="cursor-pointer">
                          {' '}
                          <ImageOpt
                            imgData={item.node.featuredImage.node?.mediaItemUrl}
                            imgAlt={item.node.featuredImage.node.altText}
                            imgLoad="lazy"
                            width={410}
                            height={318}
                            imgClass="object-cover w-full h-full"
                          />
                        </Link>
                      )}
                      <div className="blog-content py-30 lg:pr-50">
                        <h4 className="text-dark text-24 tracking-003em font-400">
                          <Link to={item.node.uri} className="cursor-pointer">
                            {' '}
                            {item.node.title}
                          </Link>
                        </h4>
                        {item.node.excerpt && parse(item.node.excerpt)}
                        <div className="link-btn mt-15">
                          <span></span>
                          <Link className="cursor-pointer" to={item.node.uri}>
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreBlog;
