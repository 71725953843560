import React from "react";
import { graphql } from "gatsby";
import Banner from "./Banner";
import GeneralContent from "./GeneralContent";
import ImageContent from "./ImageContent";
import AccommodationListing from "./AccommodationListing";
import FullImageSlider from "./FullImageSlider";
import ExploreBlog from "./ExploreBlog";
import TestimonialSlider from "./TestimonialSlider";
import ExploreCard from "./ExploreCard";
import ExperiencesListing from "./ExperiencesListing";
import OffersListing from "./OffersListing";
import IconBox from "./IconBox";
import ImageBox from "./ImageBox";
import ContentBox from "./ContentBox";
import GalleryTabs from "./GalleryTabs";
import ImagePdfContent from "./ImagePdfContent";
import ContactSection from "./ContactSection";
import SimpleContent from "./SimpleContent";
import Accordionlist from "./Accordionlist";
import FeaturedBlog from "./FeaturedBlog";
import ContactSectionWithIfame from "./ContactSectionWithIfame";
import BlogListing from "./BlogListing";
import TimelineSlider from "./TimelineSlider";
import FullWidthImage from "./FullWidthImage";
import ContentWithPdf from "./ContentWithPdf";
import RateSection from "./RateSection"
import TermsAndCondition from "./TermsAndCondition";
import SubBanner from  "./SubBanner";
import Jobs from  "./Jobs";
import InteractiveMap from "./InteractiveMap";

const Modules = (props) => {
  const modules = props.modules.pageContent;
  const components = {
    Banner,
    GeneralContent,
    ImageContent,
    AccommodationListing,
    FullImageSlider,
    ExploreBlog,
    ExploreCard,
    TestimonialSlider,
    ExperiencesListing,
    OffersListing,
    IconBox,
    ImageBox,
    ContentBox,
    GalleryTabs,
    SimpleContent,
    Accordionlist,
    ImagePdfContent,
    FeaturedBlog,
    ContactSection,
    ContactSectionWithIfame,
    BlogListing,
    TimelineSlider,
    FullWidthImage,
    ContentWithPdf,
    RateSection,
    TermsAndCondition,
    SubBanner,
    Jobs,
    InteractiveMap
  };

  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpPage_Acfpagecontent_PageContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], { key: i, module, i })
            );
          })
        : null}
    </>
  );
};

export default Modules;

export const ModulesFragment = graphql`
  fragment ModulesFragment on WpPage {
    AcfPageContent {
      pageContent {
        __typename
        ...BannerFragment
        ...GeneralContentFragment
        ...ImageContentFragment
        ...FullImageSliderFragment
        ...AccommodationListingFragment
        ...IconBoxFragment
        ...ImageBoxFragment
        ...ContentBoxFragment
        ...GalleryTabsFragment
        ...ContactSectionFragment
        ...SimpleContentFragment
        ...AccordionFragment
        ...ImagePdfContentFragment
        ...FeaturedBlogFragment
        ...ContactSectionWithIfameFragment
        ...BlogListingFragment
        ...TimelineSliderDataFragment
        ...FullWidthImageDataFragment
        ...ContentWithPdfFragment
        ...RateSectionFragment
        ...TermsAndConditionFragment
        ...SubBannerFragment
        ...JobFragment
      }
    }
  }
`;
