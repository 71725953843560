import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const ImagePdfContent = ({ module, i }) => {
  return (
    <>
      <section
        className={`explore-card lg:py-60 py-30 lg:mt-120 mt-30 imgSm ${
          module.extraClass !== '' ? module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="title-brown text-center">
            <h2>{module.title}</h2>
            {parse(module.description)}
          </div>
          <div className="flex flex-wrap mx-minus-10">
            {module.contents.map((item, i) => {
              return (
                <div className="xl:w-4/12 md:w-4/12 px-10 w-full">
                  <div className="card-bx">
                    {item.image && (
                      <ImageOpt
                        imgData={item.image?.mediaItemUrl}
                        imgAlt=""
                        imgLoad="lazy"
                        width={378}
                        height={442}
                        imgClass="block object-cover w-full"
                      />
                    )}
                    <div className="content fade-ani pt-30">
                      <h4 className="capitalize">{item.title}</h4>
                      {parse(item.description)}
                      <div className="link-btn mt-15">
                        <span></span>
                        {item.link && (
                          <Link target={item.link.target} to={item.link.url}>
                            {item.link.title}
                          </Link>
                        )}
                        {item.uploadFile && (
                          <Link
                            target="_blank"
                            to={item.uploadFile.mediaItemUrl}
                          >
                            {item.uploadFile.title}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ImagePdfContent;

export const ImagePdfContentFragment = graphql`
  fragment ImagePdfContentFragment on WpPage_Acfpagecontent_PageContent_ImagePdfContent {
    # content
    id
    title
    fieldGroupName
    extraClass
    description
    contents {
      title
      imageSize
      fieldGroupName
      description
      image {
        altText
        mediaItemUrl
        uri
        title
      }
      link {
        url
        title
        target
      }
      uploadFile {
        altText
        uri
        title
        mediaItemUrl
      }
    }
  }
`;
