import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";

const RateSection = ({ module, i }) => {
  console.log(module);
  const [tabOption, setTabOption] = useState(module.rate[0].year);
  return (
    <>
      <section className="rate-detail relative lg:py-60 py-30 lg:mt-60 mt-30">
        <div className="container-fluid">
          <div className="lg:w-6/12 w-full m-auto text-center">
            <div className="title-brown">
              <h2>{module.title}</h2>
            </div>
            <div className="content mt-0 max-w-400">
              {parse(module.description)}
            </div>
            {/* <div className="btn-custom lg:mt-25 mt-10">
              <Link
                to={module.uploadFile.url}
                target={module.uploadFile.target}
                className="button button-tan"
              >
                {module.uploadFile.title}
              </Link>
            </div> */}
            <ul className="tabs flex justify-center space-x-5 pt-50">
              {module.rate.map((item, i) => {
                return (
                  <li
                    onClick={() => {
                      setTabOption(item.year);
                    }}
                    className={`tab-link cursor-pointer text-brown capitalize font-400 text-18  ${
                      tabOption === item.year ? "tab-current" : ""
                    }`}
                    data-tab={item.year}
                  >
                    {item.year}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>

      <section className="rates-table relative bg-white lg:py-120 py-60">
        <div className="container-fluid">
          <div className="tabbox">
            {module.rate.map((item, i) => {
              return (
                <div
                  id="current-year"
                  className={`tab-content tab-current  ${
                    tabOption === item.year ? "tab-current" : "hidden"
                  }`}
                >
                  <div className="flex flex-wrap justify-between lg:gap-y-28 gap-y-10">
                    {item.rate.map((item1, i) => {
                      return (
                        <div className="lg:w-5/12 w-full rates-wrap">
                          <div className="wrapper fade-ani">
                            <span className="text-tan tracking-004em text-14 uppercase font-body font-400 mb-20 inline-block">
                              rates
                            </span>
                            <div className="title-black">
                              <h2>{item1.title}</h2>
                            </div>
                            {item.shortDescription && (
                              <div className="content max-w-400 mt-0">
                                parse(item1.shortDescription)
                              </div>
                            )}
                            <div className="link-btn mt-15">
                              <span></span>
                              {item1.link && (
                                <Link to={item1.link.url}>
                                  {item1.link.title}
                                </Link>
                              )}
                            </div>

                            <div className="pricing-table mt-30">
                              <ul>
                                {item1.ratesTable.map((item2, i) => {
                                  return (
                                    <li className="flex justify-between h-auto">
                                      <div className={`s-heading ${item2.rateThree ? "!basis-1/3" : ""}`}>
                                        <h5>{item2.title}</h5>
                                      </div>
                                      <div className="s-price">
                                        {item2.rate}
                                      </div>
                                      <div className="s-price">
                                        {item2.rateTwo}
                                      </div>
                                      {item2.rateThree && (
                                        <div className="s-price">
                                          {item2.rateThree}
                                        </div>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                              <span className="text-tan text-9 font-400 lg:my-30 my-15 inline-block">
                                *Please download our
                                rate sheet for more details
                              </span>
                              <div className="btn-custom">
                                <Link
                                  to={item1.booking.url}
                                  className="button button-brown"
                                >
                                  {item1.booking.title}
                                </Link>
                                {item1.rateSheet && (
                                  <a
                                    target="_blank"
                                    style={{marginLeft: '10px'}}
                                    className="button button-tan smscreen:!ml-0 smscreen:mt-[10px]"
                                    href={item1.rateSheet.url}
                                  >
                                    View Rate Sheet
                                  </a>
                                )}
                              </div>

                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default RateSection;

export const RateSectionFragment = graphql`
  fragment RateSectionFragment on WpPage_Acfpagecontent_PageContent_RateSection {
    # content
    fieldGroupName
    title
    uploadFile {
      url
      title
      target
    }
    rate {
      year
      fieldGroupName
      rate {
        title
        shortDescription
        rateSheet {
          url
          title
          target
        }
        ratesTable {
          title
          rate
          rateTwo
          rateThree
          fieldGroupName
        }
        link {
          url
          title
          target
        }
        booking {
          target
          title
          url
        }
      }
    }
    description
    extraClass
  }
`;
