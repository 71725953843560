import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ImageOpt from '../ImageOpt.js';
import { OfferListingData } from './OfferListingData';

const OffersListing = () => {
  const data = OfferListingData();
  const offer_data = data.allWpOffer.nodes;
  return (
    <>
      <section className="img-grid lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap lg:m-minus-15 m-0">
            {offer_data.length > 0 &&
              offer_data.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="lg:w-6/12 w-full lg:px-15 px-0 lg:mb-50 mt-20"
                  >
                    <div className="img-grid-row">
                      <Link to={item.link}>
                        <ImageOpt
                          imgData={item.featuredImage.node?.mediaItemUrl}
                          imgAlt=""
                          imgLoad="lazy"
                          width={630}
                          height={437}
                          imgClass="block object-cover w-full"
                        />
                      </Link>

                      <div className="img-content fade-ani lg:py-40 py-20">
                        <div className="title-black">
                          <Link to={item.link}>
                            <h4>{item.title}</h4>
                          </Link>
                        </div>
                        <div className="content">{parse(item.excerpt)}</div>
                        <div className="link-btn mt-15">
                          <span></span>
                          <Link to={item.link}>Explore</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default OffersListing;
export const OffersListingFragment = graphql`
  fragment OffersListingFragment on WpPage_Acfpagecontent_PageContent_OffersListing {
    # content
    id
    fieldGroupName
    extraClass
  }
`;
