import { graphql, useStaticQuery } from "gatsby";

export const TestimonialSliderData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpTestimonial {
      allWpTestimonial(sort: { fields: [menuOrder], order: [ASC] }) {
        edges {
          node {
            id
            title
            menuOrder
            content
          }
        }
      }
    }
  `);
  return data;
};
