import React, { useEffect } from 'react';
import MapImage from '../../../static/images/interactive-map.png';
import MapPin from '../../../static/images/map-pin.svg';
import MobileMapImage from '../../../static/images/mobile-map.png';

const InteractiveMap = () => {
  useEffect(() => {
    const icon1 = document.querySelector('.icon1');
    const icon2 = document.querySelector('.icon2');
    const icon3 = document.querySelector('.icon3');
    const icon4 = document.querySelector('.icon4');
    const icon5 = document.querySelector('.icon5');
    const icon6 = document.querySelector('.icon6');
    const icon7 = document.querySelector('.icon7');
    const icon8 = document.querySelector('.icon8');

    icon1.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon1.classList.add('active-icon');
    });

    icon2.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon2.classList.add('active-icon');
    });

    icon3.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon3.classList.add('active-icon');
    });

    icon4.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon4.classList.add('active-icon');
    });

    icon5.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon5.classList.add('active-icon');
    });

    icon6.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon6.classList.add('active-icon');
    });

    icon7.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon7.classList.add('active-icon');
    });

    icon8.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
      icon8.classList.add('active-icon');
    });

    //remove active-icon class from all icons when user clicks anywhere on map
    const mapImg = document.querySelector('.interactive-map__img');
    mapImg.addEventListener('click', () => {
      const icons = document.querySelectorAll('.interactive-map__icon');
      icons.forEach((icon) => {
        icon.classList.remove('active-icon');
      });
    });

    //set first tab to show on page load
    const accordianItemHeader1 = document.querySelector(
      '.accordian__item__header1'
    );
    const accordianItemBody1 = accordianItemHeader1.nextElementSibling;
    if (accordianItemHeader1.classList.contains('active')) {
      accordianItemBody1.style.maxHeight =
        accordianItemBody1.scrollHeight + 'px';
    } else {
      accordianItemBody1.style.maxHeight = 0;
    }

    const accordianItemHeaders = document.querySelectorAll(
      '.accordian__item__header'
    );

    accordianItemHeaders.forEach((accordianItemHeader) => {
      accordianItemHeader.addEventListener('click', (event) => {
        accordianItemHeader.classList.toggle('active');
        const accordianItemBody = accordianItemHeader.nextElementSibling;
        if (accordianItemHeader.classList.contains('active')) {
          accordianItemBody.style.maxHeight =
            accordianItemBody.scrollHeight + 'px';
        } else {
          accordianItemBody.style.maxHeight = 0;
        }
      });
    });
  });

  return (
    <>
      <section className="interactive-map">
        <div className="title-brown title-line title-left">
          <h3>Discover popular stops on route 62</h3>
        </div>
        <div className="interactive-map__inner mt-80 mdscreen:mt-30">
          <img
            src={MapImage}
            alt=""
            loading="lazy"
            className="interactive-map__img"
          />
          <img
            src={MobileMapImage}
            loading="lazy"
            alt=""
            className="interactive-map__img-mobile"
          />

          {/* <!-- map-icon-content group 1 --> */}
          <div className="interactive-map__link link-icon1">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon1"
            />

            <div className="icon1-content map-icon-content">
              <h5 className="map-icon-content__heading">Babylonstoren</h5>
              <p className="map-icon-content__body">
                Babylonstoren is one of the oldest Cape Dutch farms, set at the
                foot of Simonsberg in the Franschhoek wine valley. It has a
                fruit and vegetable garden of beauty and diversity, unique
                accommodation, fine food and wine, and evokes a sense of
                wellbeing.
                <br />
                <br />
                <a
                  href="https://babylonstoren.com/"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  www.babylonstoren.com
                </a>
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 2 --> */}
          <div className="interactive-map__link link-icon2">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon2"
            />

            <div className="icon2-content map-icon-content">
              <h5 className="map-icon-content__heading">
                The Karoo Desert National Botanical Garden
              </h5>
              <p className="map-icon-content__body">
                The only truly succulent garden in the southern hemisphere, and
                indeed Africa, that displays one of the largest collection of
                indigenous succulents in South Africa. The Karoo Desert National
                Botanical Garden is a truly unique garden. It cultivates and
                displays a wide variety of arid and semi-arid plants. The Garden
                lies at the foot of the Hex River Mountains range, in the town
                of Worcester.
                <br />
                <br />
                <a
                  href="http://www.sanbi.org/gardens/karoo-desert/"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  http://www.sanbi.org/gardens/karoo-desert/
                </a>
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 3 --> */}
          <div className="interactive-map__link link-icon3">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon3"
            />

            <div className="icon3-content map-icon-content">
              <h5 className="map-icon-content__heading">
                Graham Beck Wine Estate
              </h5>
              <p className="map-icon-content__body">
                Situated in the breath-taking Breede River Valley, only ten
                kilometres from the town of Robertson and an easy drive from
                Cape Town along the scenic Route 62. Located in a completely
                unique transition zone between two distinct ecosystems the farm
                fall within a fragile, fascinating and challenging environment.
                Graham Beck is passionate about restoring and conserving the
                environment and producing world class wines in harmony with
                nature.
                <br />
                <br />
                <a
                  href="https://grahambeck.com/"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  https://grahambeck.com/
                </a>
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 4 --> */}
          <div className="interactive-map__link link-icon4">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon4"
            />

            <div className="icon4-content map-icon-content">
              <h5 className="map-icon-content__heading">The Pastry Place</h5>
              <p className="map-icon-content__body">
                On your way to the reserve, why not stop at The Pastry Place.
                Tucked into a corner of ‘The Brandy Hall’ in Long Street,
                Montagu, this pâtisserie tantalises the taste buds. Sit down and
                enjoy a great cup of coffee with a freshly baked croissant or
                pie, or try their artisanal ice cream, home-made nougat.
                <br />
                <br />
                <a
                  href="https://web.facebook.com/SanbonaWildlifeReserve/posts/3932854960058132"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  https://facebook.com/SanbonaWildlifeReserve
                </a>
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 5 --> */}
          <div className="interactive-map__link link-icon5">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon5"
            />

            <div className="icon5-content map-icon-content">
              <h5 className="map-icon-content__heading">Barrydale Weavers</h5>
              <p className="map-icon-content__body">
                Barrydale Hand Weavers are the only hand weavers of fine cotton
                in South Africa, sourcing their cotton from local growers in
                South Africa or neighbouring Southern African countries. Seeing
                the weavers at the loom is awe inspiring and well worth a visit.
                They are located at 37 De Kock Street, Barrydale.
                <br />
                <br />
                <a
                  href="https://web.facebook.com/SanbonaWildlifeReserve/posts/4179025198774439"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  https://facebook.com/SanbonaWildlifeReserve
                </a>
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 6 --> */}
          <div className="interactive-map__link link-icon6">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon6"
            />

            <div className="icon6-content map-icon-content">
              <h5 className="map-icon-content__heading">Ronnies Sex Shop</h5>
              <p className="map-icon-content__body">
                This fun pub has become a regular pit-stop on the R62 in the
                Klein Karoo for bikers, local farmers and just about anyone in
                the area. Today Ronnie's Sex Shop is one of the most popular
                route 62 pubs and bikers, travellers and holidaymakers make a
                point of popping into Ronnie's for a pint or two, something to
                eat and a photograph of themselves next to the ‘Ronnies Sex
                Shop' sign.
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 7 --> */}
          <div className="interactive-map__link link-icon7">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon7"
            />

            <div className="icon7-content map-icon-content">
              <h5 className="map-icon-content__heading">
                Highgate Ostrich Show Farm
              </h5>
              <p className="map-icon-content__body">
                Highgate Ostrich Show Farm is the world’s first, finest and most
                original ostrich show. Meet ostriches face to face and learn
                more about ostrich farming! We are one of the most popular
                outdoor activities in Oudtshoorn for all ages.
                <br />
                <br />
                <a
                  href="https://highgate.co.za/"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  https://highgate.co.za/
                </a>
              </p>
            </div>
          </div>

          {/* <!-- map-icon-content group 8 --> */}
          <div className="interactive-map__link link-icon8">
            <img
              src={MapPin}
              loading="lazy"
              alt=""
              className="interactive-map__icon icon8"
            />

            <div className="icon8-content map-icon-content">
              <h5 className="map-icon-content__heading">Cango Caves</h5>
              <p className="map-icon-content__body">
                The Cango Caves are Africa's largest show caves and one of the
                Seven Wonders of Southern Africa, which makes the Cango Caves
                the archaeological and historical highlight of the Klein Karoo.
                It lies 29km from Oudtshoorn in the picturesque Cango Valley and
                is situated in a limestone ridge that runs parallel to the
                well-known Swartberg Mountains and here you’ll find the finest
                dripstone caves, with their massive halls and towering limestone
                formations in a variety of colours.
                <br />
                <br />
                <a
                  href=" https://www.cango-caves.co.za/"
                  target="_blank"
                  className="map-icon-content__link"
                >
                  {' '}
                  https://www.cango-caves.co.za/
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* <!-- accordian for mobile only --> */}
        <div className="accordian mobile-tabs-accordian md:hidden">
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header accordian__item__header1 active">
              Babylonstoren
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  Babylonstoren is one of the oldest Cape Dutch farms, set at
                  the foot of Simonsberg in the Franschhoek wine valley. It has
                  a fruit and vegetable garden of beauty and diversity, unique
                  accommodation, fine food and wine, and evokes a sense of
                  wellbeing.
                  <br />
                  <br />
                  <a
                    href="https://babylonstoren.com/"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    www.babylonstoren.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              The Karoo Desert National Botanical Garden
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  The only truly succulent garden in the southern hemisphere,
                  and indeed Africa, that displays one of the largest collection
                  of indigenous succulents in South Africa. The Karoo Desert
                  National Botanical Garden is a truly unique garden. It
                  cultivates and displays a wide variety of arid and semi-arid
                  plants. The Garden lies at the foot of the Hex River Mountains
                  range, in the town of Worcester.
                  <br />
                  <br />
                  <a
                    href="http://www.sanbi.org/gardens/karoo-desert/"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    http://www.sanbi.org/gardens/karoo-desert/
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              Graham Beck Wine Estate
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  Situated in the breath-taking Breede River Valley, only ten
                  kilometres from the town of Robertson and an easy drive from
                  Cape Town along the scenic Route 62. Located in a completely
                  unique transition zone between two distinct ecosystems the
                  farm fall within a fragile, fascinating and challenging
                  environment. Graham Beck is passionate about restoring and
                  conserving the environment and producing world class wines in
                  harmony with nature.
                  <br />
                  <br />
                  <a
                    href="https://grahambeck.com/"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    https://grahambeck.com/
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              The Pastry Place
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  On your way to the reserve, why not stop at The Pastry Place.
                  Tucked into a corner of ‘The Brandy Hall’ in Long Street,
                  Montagu, this pâtisserie tantalises the taste buds. Sit down
                  and enjoy a great cup of coffee with a freshly baked croissant
                  or pie, or try their artisanal ice cream, home-made nougat.
                  <br />
                  <br />
                  <a
                    href="https://web.facebook.com/SanbonaWildlifeReserve/posts/3932854960058132"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    https://facebook.com/SanbonaWildlifeReserve
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              Barrydale Weavers
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  Barrydale Hand Weavers are the only hand weavers of fine
                  cotton in South Africa, sourcing their cotton from local
                  growers in South Africa or neighbouring Southern African
                  countries. Seeing the weavers at the loom is awe inspiring and
                  well worth a visit. They are located at 37 De Kock Street,
                  Barrydale.
                  <br />
                  <br />
                  <a
                    href="https://web.facebook.com/SanbonaWildlifeReserve/posts/4179025198774439"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    https://facebook.com/SanbonaWildlifeReserve
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              Ronnies Sex Shop
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  This fun pub has become a regular pit-stop on the R62 in the
                  Klein Karoo for bikers, local farmers and just about anyone in
                  the area. Today Ronnie's Sex Shop is one of the most popular
                  route 62 pubs and bikers, travellers and holidaymakers make a
                  point of popping into Ronnie's for a pint or two, something to
                  eat and a photograph of themselves next to the ‘Ronnies Sex
                  Shop' sign. You can also get something to eat.
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              Highgate Ostrich Show Farm
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  Highgate Ostrich Show Farm is the world’s first, finest and
                  most original ostrich show. Meet ostriches face to face and
                  learn more about ostrich farming! We are one of the most
                  popular outdoor activities in Oudtshoorn for all ages.
                  <br />
                  <br />
                  <a
                    href="https://highgate.co.za/"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    https://highgate.co.za/
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="accordian__item mobile-tabs-accordian__item">
            <div className="accordian__item__header mobile-tabs-accordian__item__header">
              Cango Caves
            </div>
            <div className="accordian__item__body mobile-tabs-accordian__item__body">
              <div className="accordian__item__body__content mobile-tabs-accordian__item__body__content">
                <p className="map-icon-content__body">
                  The Cango Caves are Africa's largest show caves and one of the
                  Seven Wonders of Southern Africa, which makes the Cango Caves
                  the archaeological and historical highlight of the Klein
                  Karoo. It lies 29km from Oudtshoorn in the picturesque Cango
                  Valley and is situated in a limestone ridge that runs parallel
                  to the well-known Swartberg Mountains and here you’ll find the
                  finest dripstone caves, with their massive halls and towering
                  limestone formations in a variety of colours.
                  <br />
                  <br />
                  <a
                    href=" https://www.cango-caves.co.za/"
                    target="_blank"
                    className="map-icon-content__link"
                  >
                    {' '}
                    https://www.cango-caves.co.za/
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InteractiveMap;
