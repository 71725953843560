import React, { useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const TermsAndCondition = ({ module, i }) => {
  const [tabOption, setTabOption] = useState(module.terms[0].title);
  return (
    <>
      <section className="zigzag relative lg:pt-120 pt-60 lg:pb-60 pb-30">
        <div className="container-fluid">
          <span>Rates</span>
          <div className="title-black">
            <h2>terms & Conditions </h2>
          </div>
          <ul className="tabs flex flex-wrap lg:pt-30 pt-20 gap-x-10 gap-y-5">
            {module.terms.map((item, i) => {
              return (
                <li
                  onClick={() => {
                    setTabOption(item.title);
                  }}
                  className={`tab-link cursor-pointer  text-brown capitalize font-400 text-18 ${
                    tabOption === item.title ? "tab-current" : ""
                  }`}
                  data-tab={item.title}
                >
                  {item.title}
                </li>
              );
            })}
          </ul>
          <div className="tabbox">
            {module.terms.map((item, i) => {
              return (
                <div
                  id="General"
                  className={`tab-content tab-current ${
                    tabOption === item.title ? "tab-current" : "hidden"
                  }`}
                >
                  <div className="content max-w-800">
                    {parse(item.description)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;

export const TermsAndConditionFragment = graphql`
  fragment TermsAndConditionFragment on WpPage_Acfpagecontent_PageContent_TermsAndCondition {
    # content
    id
    title
    extraClass
    fieldGroupName
    terms {
      title
      fieldGroupName
      description
    }
  }
`;
