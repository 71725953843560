import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
const ContentBox = ({ module, i }) => {
  return (
    <>
      <section className="smImgGrid  lg:py-60 py-30 lg:pb-0 ">
        <div className="container-fluid">
          <div className="xl:w-6/12 w-full m-auto text-center">
            <div className="title-brown mb-10">
              <h2>{module.heading}</h2>
            </div>
            <div className="content">{parse(module.descriptions)}</div>
          </div>
          <div className="flex flex-wrap lg:mx-minus-20 lg:mt-60 mt-30">
            {module.contentBoxes.length > 0 &&
              module.contentBoxes.map((item, i) => {
                return (
                  <div className="xl:w-4/12 fade-ani md:w-4/12 xl:px-20 md:px-20 lg:mb-50 mb-20 w-full ">
                    <div className="card-bx lg:mb-0 mb-50 bg-white h-[320px]">
                    <div className='bg-brown p-20 text-center'>
                        <h4 className="text-white text-30 font-400 tracking-003em">
                          {item.heading}
                        </h4>
                        <p className="text-white text-18 font-200 opacity-70 tracking-003em">
                          {item.quote}
                        </p>
                    </div>
                    
                      <div className="content pt-30 p-30 lg:px-50">
                        <p className='career-list'>{parse(item.descriptions)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentBox;
export const ContentBoxFragment = graphql`
  fragment ContentBoxFragment on WpPage_Acfpagecontent_PageContent_ContentBox {
    # content
    id
    heading
    descriptions
    contentBoxes {
        heading
        quote
        descriptions
    }
  }
`;
