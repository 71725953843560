import { Link } from 'gatsby';
import parse from 'html-react-parser';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageOpt from '../ImageOpt.js';
import { AccommodationsData } from './AccommodationsData';

const AccommodationListing = ({ module }) => {
  const data = AccommodationsData();
  const swiper_data = data.allWpAccommodation.edges;

  const [headerIsVisible, setheaderIsVisible] = React.useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);

  useEffect(() => {
    if (headerIsVisible === true) {
      document.querySelector('.header').classList.remove('hide-header');
    } else if (headerIsVisible === false) {
      document.querySelector('.header').classList.add('hide-header');
    }
  }, [headerIsVisible]);

  function openModal() {
    setheaderIsVisible(false);
    setIsOpen(true);
  }
  function openModal2() {
    setheaderIsVisible(false);
    setIsOpen2(true);
  }
  function openModal3() {
    setheaderIsVisible(false);
    setIsOpen3(true);
  }
  function closeModal() {
    setIsOpen(false);
    setheaderIsVisible(true);
  }
  function closeModal2() {
    setIsOpen2(false);
    setheaderIsVisible(true);
  }
  function closeModal3() {
    setIsOpen3(false);
    setheaderIsVisible(true);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      padding: '50px',
      zIndex: '9999999999999',
      postion: 'fixed',
    },
  };

  useEffect(() => {
    console.log(
      data.allWpAccommodation.edges[0].node.roomsContent
        .accommodationsContent[3].heading
    );
  }, []);

  return (
    <>
      <div className="page-title lg:py-60 py-30  lg:mt-60 mt-30">
        <div className="container-fluid pr-0">
          <div className="title-brown title-line title-left">
            <h3>{module.heading}</h3>
          </div>
        </div>
      </div>
      {swiper_data.map((item, i) => {
        return (
          <section
            className={`zigzag lg:py-60 py-30  ${module.imageSize} ${module.extraClass}`}
          >
            <div className="container-fluid">
              <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
                <div
                  className={`lg:w-6/12 w-full ${
                    i % 2 === 0 ? 'lg:order-2' : ''
                  }`}
                >
                  <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                    <span>Accommodation</span>
                    <div className="title-black">
                      <h2>{item.node.title}</h2>
                    </div>
                    <div className="tooltip-icon flex lg:space-x-5 space-x-5 py-10">
                      {item.node.roomsContent.accommodationFutures.map(
                        (item1) => {
                          return (
                            <div
                              key={item1.icon.altText}
                              className="ticon relative"
                            >
                              <Tooltip
                                placement="topLeft"
                                showArrow={false}
                                overlayInnerStyle={{
                                  backgroundColor: '#C39B81',
                                  color: '#fff',
                                  border: '#C39B81',
                                  borderRadius: '0px',
                                  fontSize: '11px',
                                  opacity: '100',
                                }}
                                overlay={
                                  <div
                                    overlayStyle={{
                                      backgroundColor: '#C39B81',
                                    }}
                                  >
                                    {item1.title}
                                  </div>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div>
                                  <img
                                    src={item1.icon.mediaItemUrl}
                                    loading="lazy"
                                    alt={item1.icon.altText}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div className="content">{parse(item.node.content)}</div>
                    <div className="btn-custom lg:mt-40 mt-20">
                      {item.node.roomsContent.exploreButton && (
                        <Link
                          to={item.node.roomsContent.exploreButton.url}
                          target={item.node.roomsContent.exploreButton.target}
                          className="button button-tan"
                        >
                          {item.node.roomsContent.exploreButton.title}
                        </Link>
                      )}

                      {i === 0 && (
                        <>
                          <a href="" className="text-link" onClick={openModal}>
                            View Full Rates
                          </a>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                          >
                            <button
                              className="absolute right-60"
                              onClick={closeModal}
                            >
                              <img
                                src="../../images/modal-close.svg"
                                loading="lazy"
                                alt=""
                              />
                            </button>
                            <div className="rates-modal">
                              <div className="modal-title lg:pt-50 pt-20 lg:pl-30 pl-0 border-b-1 border-tan border-opacity-40 pb-30">
                                <span className="text-tan tracking-004em text-14 uppercase font-body font-400">
                                  {
                                    item.node.roomsContent
                                      .accommodationsContent[3].heading
                                  }
                                </span>
                                {item.node.roomsContent.accommodationsContent[3]
                                  .years && (
                                  <h2 className="pt-15">
                                    {
                                      item.node.roomsContent
                                        .accommodationsContent[3].years
                                    }
                                  </h2>
                                )}
                              </div>
                              <div className="modal-content">
                                <div className="lg:grid lg:grid-cols-4 lg:gap-x-20 grid grid-cols-2 md:gap-0 lg:px-30 px-0 modal-m-border">
                                  {item.node.roomsContent
                                    .accommodationsContent[3].viewRates &&
                                    item.node.roomsContent.accommodationsContent[3].viewRates.map(
                                      (item, i) => {
                                        return (
                                          <div className="modal-col lg:pt-50 pt-20">
                                            <h6 className="text-14 text-dark text-opacity-70 font-400 pb-20">
                                              {item.date}
                                            </h6>
                                            <h4 className="text-brown tracking-003em capitalize font-400">
                                              {item.rate}
                                            </h4>
                                            <span className="text-10 tracking-003em lowercase font-300 text-brown">
                                              {item.tagline}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                {item.node.roomsContent.accommodationsContent[3]
                                  .popupbutton && (
                                  <div className="btn-custom lg:pl-30 pl-0 pt-30">
                                    <a
                                      className="button button-tan"
                                      href={
                                        item.node.roomsContent
                                          .accommodationsContent[3].popupbutton
                                          .url
                                      }
                                    >
                                      {
                                        item.node.roomsContent
                                          .accommodationsContent[3].popupbutton
                                          .title
                                      }
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Modal>
                        </>
                      )}
                      {i === 1 && (
                        <>
                          <a href="" className="text-link" onClick={openModal2}>
                            View Full Rates
                          </a>
                          <Modal
                            isOpen={modalIsOpen2}
                            onRequestClose={closeModal2}
                            style={customStyles}
                          >
                            <button
                              className="absolute right-60"
                              onClick={closeModal2}
                            >
                              <img
                                src="../../images/modal-close.svg"
                                loading="lazy"
                                alt=""
                              />
                            </button>
                            <div className="rates-modal">
                              <div className="modal-title lg:pt-50 pt-20 lg:pl-30 pl-0 border-b-1 border-tan border-opacity-40 pb-30">
                                <span className="text-tan tracking-004em text-14 uppercase font-body font-400">
                                  {
                                    item.node.roomsContent
                                      .accommodationsContent[3].heading
                                  }
                                </span>
                                {item.node.roomsContent.accommodationsContent[3]
                                  .years && (
                                  <h2 className="pt-15">
                                    {
                                      item.node.roomsContent
                                        .accommodationsContent[3].years
                                    }
                                  </h2>
                                )}
                              </div>
                              <div className="modal-content">
                                <div className="lg:grid lg:grid-cols-4 lg:gap-x-20 grid grid-cols-2 md:gap-0 lg:px-30 px-0 modal-m-border">
                                  {item.node.roomsContent
                                    .accommodationsContent[3].viewRates &&
                                    item.node.roomsContent.accommodationsContent[3].viewRates.map(
                                      (item, i) => {
                                        return (
                                          <div className="modal-col lg:pt-50 pt-20">
                                            <h6 className="text-14 text-dark text-opacity-70 font-400 pb-20">
                                              {item.date}
                                            </h6>
                                            <h4 className="text-brown tracking-003em capitalize font-400">
                                              {item.rate}
                                            </h4>
                                            <span className="text-10 tracking-003em lowercase font-300 text-brown">
                                              {item.tagline}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                {item.node.roomsContent.accommodationsContent[3]
                                  .popupbutton && (
                                  <div className="btn-custom lg:pl-30 pl-0 pt-30">
                                    <a
                                      className="button button-tan"
                                      href={
                                        item.node.roomsContent
                                          .accommodationsContent[3].popupbutton
                                          .url
                                      }
                                    >
                                      {
                                        item.node.roomsContent
                                          .accommodationsContent[3].popupbutton
                                          .title
                                      }
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Modal>
                        </>
                      )}
                      {i === 2 && (
                        <>
                          <a href="" className="text-link" onClick={openModal3}>
                            View Full Rates
                          </a>
                          <Modal
                            isOpen={modalIsOpen3}
                            onRequestClose={closeModal3}
                            style={customStyles}
                          >
                            <button
                              className="absolute right-60"
                              onClick={closeModal3}
                            >
                              <img
                                src="../../images/modal-close.svg"
                                loading="lazy"
                                alt=""
                              />
                            </button>
                            <div className="rates-modal">
                              <div className="modal-title lg:pt-50 pt-20 lg:pl-30 pl-0 border-b-1 border-tan border-opacity-40 pb-30">
                                <span className="text-tan tracking-004em text-14 uppercase font-body font-400">
                                  {
                                    item.node.roomsContent
                                      .accommodationsContent[3].heading
                                  }
                                </span>
                                {item.node.roomsContent.accommodationsContent[3]
                                  .years && (
                                  <h2 className="pt-15">
                                    {
                                      item.node.roomsContent
                                        .accommodationsContent[3].years
                                    }
                                  </h2>
                                )}
                              </div>
                              <div className="modal-content">
                                <div className="lg:grid lg:grid-cols-4 lg:gap-x-20 grid grid-cols-2 md:gap-0 lg:px-30 px-0 modal-m-border">
                                  {item.node.roomsContent
                                    .accommodationsContent[3].viewRates &&
                                    item.node.roomsContent.accommodationsContent[3].viewRates.map(
                                      (item, i) => {
                                        return (
                                          <div className="modal-col lg:pt-50 pt-20">
                                            <h6 className="text-14 text-dark text-opacity-70 font-400 pb-20">
                                              {item.date}
                                            </h6>
                                            <h4 className="text-brown tracking-003em capitalize font-400">
                                              {item.rate}
                                            </h4>
                                            <span className="text-10 tracking-003em lowercase font-300 text-brown">
                                              {item.tagline}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                {item.node.roomsContent.accommodationsContent[3]
                                  .popupbutton && (
                                  <div className="btn-custom lg:pl-30 pl-0 pt-30">
                                    <a
                                      className="button button-tan"
                                      href={
                                        item.node.roomsContent
                                          .accommodationsContent[3].popupbutton
                                          .url
                                      }
                                    >
                                      {
                                        item.node.roomsContent
                                          .accommodationsContent[3].popupbutton
                                          .title
                                      }
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                    {i === 0 && module.extraClass === 'add-disclaimer' && (
                      <div className="content">
                        <p className="disclaimer-text text-[12px] opacity-80">
                          *Dwyka Tented Lodge is closed for annual maintenance
                          until the 7th of October.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="lg:w-6/12 w-full">
                  <div className="img-slider">
                    <Swiper
                      speed={1000}
                      effect={'fade'}
                      loop={true}
                      navigation={true}
                      modules={[Navigation, Autoplay, Pagination, EffectFade]}
                    >
                      {item.node.roomsContent.accommodationGallery.map(
                        (item, i) => {
                          return (
                            <div key={i}>
                              <SwiperSlide>
                                {item && (
                                  <ImageOpt
                                    imgData={item?.mediaItemUrl}
                                    imgAlt={item.altText}
                                    imgLoad="lazy"
                                    width={645}
                                    height={537}
                                    imgClass="object-cover w-full h-full"
                                  />
                                )}
                              </SwiperSlide>
                            </div>
                          );
                        }
                      )}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default AccommodationListing;
