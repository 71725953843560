import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const FullWidthImage = ({ module, i }) => {
  return (
    <>
      <section className="lg:py-60 py-30">
        <div className="container-fluid pr-0 mPaddingRemove">
          <div className="title-brown title-line title-left">
            <h3>{module.heading}</h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            Tag="section"
            className="promotion-section mt-80 p-50 min-h-719 bg-cover relative"
          >
            <ImageOpt
              imgData={module.backgroundImage.mediaItemUrl}
              imgAlt="Map"
              imgLoad="lazy"
              width={1290}
              height={719}
              imgClass="object-cover h-full w-full absolute top-0 left-0 z-[1] "
            />
            <div className="relative z-[2]">
              <div className="flex justify-between  mdscreen:flex-wrap items-center lg:pt-200 gap-[30px]">
                <div className="content">
                  {module.fullLogo && (
                    <ImageOpt
                      imgData={module.fullLogo.mediaItemUrl}
                      imgAlt="logo"
                      imgLoad="lazy"
                      width={150}
                      height={87}
                      imgClass="object-cover h-full w-full max-w-150"
                    />
                  )}
                  {parse(module.descriptions)}
                  <Link
                    to={module.button.url}
                    target={module.button.target}
                    className="button button-transparent mt-30"
                  >
                    {module.button.title}
                    <img
                      src="../images/visit.png"
                      loading="lazy"
                      className="inline-block ml-10"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="img">
                  {module.image && (
                    <ImageOpt
                      imgData={module.image.mediaItemUrl}
                      imgAlt="Map"
                      imgLoad="lazy"
                      width={1290}
                      height={719}
                      imgClass="object-cover h-full w-full max-w-640"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullWidthImage;
export const FullWidthImageDataFragment = graphql`
  fragment FullWidthImageDataFragment on WpPage_Acfpagecontent_PageContent_FullWidthImage {
    # content
    id
    heading
    image {
      altText
      uri
      title
      mediaItemUrl
    }
    fullLogo {
      altText
      uri
      title
      mediaItemUrl
    }
    fieldGroupName
    extraClass
    descriptions
    button {
      target
      title
      url
    }
    backgroundImage {
      altText
      mediaItemUrl
      uri
      title
    }
  }
`;
