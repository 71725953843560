import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const ContactSection = ({ module, i }) => {
  return (
    <>
      <section
        className={`zigzag portrait lg:py-60 py-30 ${
          module.extraClass !== '' ? module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === 'left' ? 'lg:order-2' : ''
              }`}
            >
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                {module.perHeading && <span>{module.perHeading}</span>}
                <div className="title-black">
                  <h2>{module.heading}</h2>
                </div>
                <div className="content">
                  <div className="ct-info ">
                    <div
                      className={`ct-block ct-blog-top ${
                        module.description2 ? 'border-b ' : ''
                      }`}
                    >
                      {parse(module.description)}
                    </div>
                    {module.description2 && (
                      <div className="ct-block">
                        {parse(module.description2)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <ImageOpt
                imgData={module?.image.mediaItemUrl}
                imgAlt={module.image.altText}
                imgLoad="lazy"
                width={645}
                height={648}
                imgClass="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;

export const ContactSectionFragment = graphql`
  fragment ContactSectionFragment on WpPage_Acfpagecontent_PageContent_ContactSection {
    # content
    id
    description
    description2
    extraClass
    heading
    imagePosition
    imageSize
    perHeading
    image {
      altText
      uri
      title
      mediaItemUrl
    }
  }
`;
