import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
const axios = require("axios").default;

const OfferPopup = () => {
  const [offerPopupIsOpen, setOfferPopupIsOpen] = useState(false);
  const [pagePath, setPagePath] = useState(false);

  useEffect(() => {
    setPagePath(window.location.pathname); 
  });

  useEffect(() => {
    let offerPopupShown = sessionStorage.getItem('offerPopupShown');

    if (offerPopupIsOpen && offerPopupShown !== 'shown') {
      const popup = document.querySelector('#offer-popup');
      const popupInner = document.querySelector('.offer-popup__inner');
      const body = document.querySelector('body');
      body.classList.add('lock-mobile-scroll');
      popup.style.display = 'block';
      setTimeout(function () {
        popupInner.style.opacity = '100';
        popupInner.style.transform = 'translateX(0)';
      }, 100);
    }
  }, [offerPopupIsOpen]);

  const closeOfferPopup = () => {
    const popup = document.querySelector('#offer-popup');
    const popupInner = document.querySelector('.offer-popup__inner');
    const body = document.querySelector('body');
    body.classList.remove('lock-mobile-scroll');
    popupInner.style.opacity = '0';
    popupInner.style.transform = 'translateX(5%)';
    setTimeout(function () {
      popup.style.display = 'none';
    }, 400);

    sessionStorage.setItem('offerPopupShown', 'shown');
  };

  useEffect(() => {
    setTimeout(function () {
      setOfferPopupIsOpen(true);
    }, 4000);
  }, []);

  return (
    <>
      <section
        id="offer-popup"
        className={`offer-popup fixed z-[999999] bottom-[60px] right-[30px] mdscreen:right-0 mdscreen:bottom-0 w-[700px] mdscreen:w-full mdscreen:h-screen`}
        style={{ display: 'none' }}
      >
        {pagePath != "/offer/" &&
        <div className="offer-popup__inner flex mdscreen:flex-col mdscreen:justify-center mdscreen:h-full mdscreen:px-20 mdscreen:py-40 bg-black/50 opacity-0 translate-x-[5%] transition-all ease-linear duration-300">
          <div className="offer-popup__img-wrapper w-1/3 mdscreen:w-full mdscreen:h-[180px]">
            <img
              src="/images/popup-img.webp"
              alt=""
              className="offer-popup__img h-full w-full object-cover object-center"
            />
          </div>
          <div className="offer-popup__content w-2/3 mdscreen:w-full mdscreen:h-auto mdscreen:max-h-full px-30 py-40 relative bg-white">
            <button
              onClick={() => closeOfferPopup()}
              className="offer-popup__close-btn absolute top-20 right-20 hover:cursor-pointer ipad:hidden"
            >
              <img
                src="../images/close.svg"
                alt=""
                className="offer-popup__close-btn__icon h-[16px] w-[16px] object-contain"
              />
            </button>
            <div className="title-black">
              <h2 className="offer-popup__heading">Stay 4, Pay 3 Offer</h2>
            </div>
            <button
            onClick={() => closeOfferPopup()}
            className="offer-popup__close-btn absolute z-[9999999] top-[15px] right-[15px] hover:cursor-pointer md:block xl2:hidden"
          >
            <img
              src="/images/close.svg"
              alt=""
              className="offer-popup__close-btn__icon h-[20px] w-[20px] object-contain"
            />
          </button>
            <div className="content">
              <p className="offer-popup__description overflow-auto max-h-[160px] pt-10">
              Receive one night on a complimentary basis when booking 4 consecutive nights at any of our luxury lodges. Sanbona Wildlife Reserve is packed with opportunities to learn, love and explore nature around you while residing in ultimate comfort.
              </p>
            </div>
            <div className="offer-popup__buttons btn-custom mt-30 mdscreen:flex mdscreen:flex-col">
              <a
                href="https://campaigns.sanbona.com/stay-4-nights-only-pay-for-3/"
                className="button button-tan mr-20 mdscreen:mr-0 mdscreen:text-center"
                target="_blank"
              >
                Explore Offer
              </a>
            </div>
          </div>
        </div>
        }
      </section>
    </>
  );
};

export default OfferPopup;