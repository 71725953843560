import React from "react";
import parse from "html-react-parser";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { TestimonialSliderData } from "./TestimonialSliderData";

const TestimonialSlider = ({ module }) => {
  const data = TestimonialSliderData();
  const testimonial_data = data.allWpTestimonial.edges;

  return (
    <>
      <section className="text-slider lg:py-60 py-30">
        <div className="container m-auto text-center fade-ani">
          <div className="text-center">
            <span className="text-slider-title">
              Guest feedback
            </span>
          </div>
          <Swiper
            speed={1000}
            loop={false}
            navigation={false}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
          >
            {testimonial_data.map((item, i) => {
              return (
                <SwiperSlide>
                  <div className="text-slider-content text-center lg:pt-50 pt-30 lg:px-80 px-0">
                    <h6 className="lg:text-20 text-16 leading-[28px]">
                      {parse(item.node.content)}
                    </h6>
                    <span className="author-name lg:mt-50 mt-25 ">
                      {item.node.title}
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default TestimonialSlider;
