import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { EffectFade, FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageOpt from '../ImageOpt.js';

const TimelineSlider = ({ module, i }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <section className="full-img-slider timelineSlider lg:py-60 py-30">
        <div className="container-fluid pl-0 mPaddingRemove">
          <div className="thumb-slider">
            <div className="container-fluid">
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={13}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[Navigation, FreeMode, Thumbs]}
                className="mySwiper"
                breakpoints={{
                  300: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                  },
                  640: {
                    slidesPerView: 8,
                    spaceBetween: 5,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1366: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1367: {
                    slidesPerView: 13,
                    spaceBetween: 10,
                  },
                }}
              >
                {module.timelineSliders.map((item, i) => {
                  return (
                    <SwiperSlide>
                      <div className="date-year-thumb"></div>
                      <div className="data-year">
                        <span className="text-brown text-18 font-400 cursor-pointer">
                          {item.year}
                        </span>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div className="title-brown title-line title-right">
            <h3>a timeline of Sanbona</h3>
          </div>
        </div>
        <div className="container-fluid">
          <div className="full-slider bg-white lg:mt-60 mt-30">
            <Swiper
              speed={1000}
              effect={'fade'}
              thumbs={{ swiper: thumbsSwiper }}
              loop={true}
              pagination={{
                type: 'fraction',
                formatFractionCurrent: function (number) {
                  return ('0' + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                  return ('0' + number).slice(-2);
                },
                renderFraction: function (currentClass, totalClass) {
                  return (
                    '<span class="' +
                    currentClass +
                    '"></span>' +
                    '<div class="counter-lne">/</div>  ' +
                    '<span class="' +
                    totalClass +
                    '"></span>'
                  );
                },
              }}
              navigation={true}
              modules={[Navigation, Pagination, EffectFade, FreeMode, Thumbs]}
            >
              {module.timelineSliders.map((item, i) => {
                return (
                  <SwiperSlide>
                    <div>
                      {item.image && (
                        <ImageOpt
                          imgData={item.image.mediaItemUrl}
                          imgAlt={item.image.altText}
                          imgLoad="lazy"
                          width={1290}
                          height={630}
                          imgClass="h-full w-full object-cover"
                        />
                      )}
                    </div>
                    <span className="full-img-slider-text mt-50 inline-block text-18 text-brown font-400 pl-[150px]">
                      {item.year}: {item.title}
                    </span>
                    <p className="px-[150px] full-img-slider-text pt-10">
                      {parse(item.descriptions)}
                    </p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default TimelineSlider;
export const TimelineSliderDataFragment = graphql`
  fragment TimelineSliderDataFragment on WpPage_Acfpagecontent_PageContent_TimelineSlider {
    # content
    id
    extraClass
    heading
    timelineSliders {
      year
      title
      descriptions
      image {
        altText
        mediaItemUrl
        uri
        title
      }
    }
  }
`;
