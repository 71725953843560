import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const SubBanner = ({ module, i }) => {
  return (
    <>
      <section className="banner h-screen relative">
        <div className="banner-img h-full flex items-center justify-center">
          <div className="banner-img h-full w-full flex items-center justify-center relative">
            <ImageOpt
              imgData={module?.backgroundImage.mediaItemUrl}
              imgAlt="banner"
              imgLoad="eager"
              width={1440}
              height={856}
              imgFetchPriority="high"
              imgClass="banner-img h-full w-full absolute top-0 left-0 z-[1] object-cover"
            />
          </div>
          <div className="banner-info py-150 px-50 mt-50 bg-tanlight text-center fade-ani relative w-full h-full z-[5] md:w-2/3">
            <h1 className="lg:text-60 md:text-46 text-36 lg:leading-80 tracking-006em capitalize leading-50 text-brown">
              {module.heading}
            </h1>
            <div className="max-w-[470px] m-auto">
              {parse(module.description)}
            </div>
            <div className="text-center mt-30">
              <Link
                className="button button-tan cursor-pointer hamburger menu-open-test transition-all duration-300 "
                to="/"
              >
                go back to home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubBanner;

export const SubBannerFragment = graphql`
  fragment SubBannerFragment on WpPage_Acfpagecontent_PageContent_SubBanner {
    # content
    id
    heading
    fullVideo
    fieldGroupName
    description
    button {
      url
      title
      target
    }
    backgroundImage {
      altText
      uri
      mediaItemUrl
    }
    button {
      target
      title
      url
    }
    preHeadings {
      preHeading
    }
    extraClass
    backgroundVideo
  }
`;
