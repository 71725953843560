import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const ContactSectionWithIfame = ({ module, i }) => {
  return (
    <>
      <section
        className={`zigzag portrait lg:py-60 py-30 ${
          module.extraClass !== '' ? module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <span>{module.perHeading}</span>
                <div className="title-black">
                  <h2>{module.heading}</h2>
                </div>
                <div className="content">
                  <div className="ct-info">
                    <div className="ct-block ct-blog-top">
                      {parse(module.description)}
                    </div>
                    <div className="ct-block">{parse(module.description2)}</div>
                    <div className="ct-block">
                      <p>Follow Us</p>
                      <div className="sicon">
                        <ul className="flex flex-wrap space-x-4">
                          {module.socialMedia.map((item, i) => {
                            return (
                              <li>
                                <Link to={item.link}>
                                  <img
                                    src={item.icon.mediaItemUrl}
                                    loading="lazy"
                                    alt={item.selectIcon}
                                  />
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="img gatsby-image-wrapper">
                {parse(module.iframe)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSectionWithIfame;
export const ContactSectionWithIfameFragment = graphql`
  fragment ContactSectionWithIfameFragment on WpPage_Acfpagecontent_PageContent_ContactSectionWithIfame {
    # content
    id
    socialMedia {
      selectIcon
      link
      fieldGroupName
      icon {
        altText
        mediaItemUrl
        uri
        title
      }
    }
    perHeading
    imageSize
    imagePosition
    image {
      altText
      uri
      title
      mediaItemUrl
    }
    iframe
    heading
    fieldGroupName
    extraClass
    description2
    description
  }
`;
