import { graphql } from 'gatsby';
import React from 'react';

const IconBox = ({ module, i }) => {
  return (
    <>
      <section className="icon-list fade-ani lg:py-60 py-30 lg:mt-60 mt-30">
        <div className="container m-auto">
          <div className="lg:w-9/12 w-full m-auto">
            <div className="text-center">
              <h2 className="text-brown !mb-50">{module.heading}</h2>
            </div>
            <div className="grid lg:grid-cols-6 lg:gap-5 md:grid-cols-6 md:gap-5 sm:grid-cols-3 grid-cols-2">
              {module.iconBoxes.length > 0 &&
                module.iconBoxes.map((item, i) => {
                  return (
                    <div className="icon-content text-center">
                      <img
                        src={item.image.mediaItemUrl}
                        loading="lazy"
                        className="m-auto"
                        alt=""
                      />
                      <span className="mt-15 max-w-150 px-10 inline-block text-dark text-12 text-opacity-70 font-400">
                        {item.heading}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IconBox;
export const IconBoxFragment = graphql`
  fragment IconBoxFragment on WpPage_Acfpagecontent_PageContent_IconBox {
    # content
    id
    extraClass
    heading
    iconBoxes {
      fieldGroupName
      heading
      image {
        altText
        mediaItemUrl
        title
      }
    }
  }
`;
