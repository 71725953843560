import { graphql, useStaticQuery } from 'gatsby';
export const BrandSettingsData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        brandSettings {
          fluxBrand {
            globalCard {
              heading
              description
              image {
                altText
                mediaItemUrl
              }
              button {
                target
                title
                url
              }
            }
            cardMainHeading
          }
        }
      }
    }
  `);
  return data.wp.brandSettings.fluxBrand;
};
