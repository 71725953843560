import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';
const ImageBox = ({ module, i }) => {
  return (
    <>
      <section className="smImgGrid lg:py-60 py-30">
        <div className="container-fluid">
          <div className="xl:w-4/12 w-full m-auto text-center">
            <div className="title-brown mb-10">
              <h2>{module.heading}</h2>
            </div>
            <div className="content">{parse(module.descriptions)}</div>
          </div>
          <div className="flex flex-wrap lg:mx-minus-20 lg:mt-60 mt-30">
            {module.imageBoxes.length > 0 &&
              module.imageBoxes.map((item, i) => {
                return (
                  <div className="xl:w-3/12 fade-ani md:w-4/12 xl:px-20 md:px-20 lg:mb-50 mb-20 w-full ">
                    <div className="card-bx lg:mb-0 mb-50">
                      {item.image && (
                        <ImageOpt
                          imgData={item.image.mediaItemUrl}
                          imgAlt={item.image.altText}
                          imgLoad="lazy"
                          width={293}
                          height={235}
                          imgClass="object-cover h-full w-full"
                        />
                      )}
                      <div className="content pt-30">
                        <h4 className="text-brown text-18 font-400 tracking-003em">
                          {item.image.title}
                        </h4>
                        {parse(item.descriptions)}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageBox;
export const ImageBoxFragment = graphql`
  fragment ImageBoxFragment on WpPage_Acfpagecontent_PageContent_ImageBox {
    # content
    id
    heading
    descriptions
    imageBoxes {
      heading
      descriptions
      image {
        altText
        mediaItemUrl
        uri
        title
      }
    }
  }
`;
