import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const ImageContent = ({ module }) => {
  return (
    <>
      <section
        className={`zigzag lg:py-60 py-30 ${module.imageSize} ${module.extraClass}`}
      >
        <div className="container-fluid ">
          <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === 'left' ? 'lg:order-2' : ''
              }`}
            >
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <span>{module.perHeading}</span>
                <div className="title-black ">
                  <h2>{module.heading}</h2>
                </div>
                <div className="content">{parse(module.description)}</div>
                <div className="btn-custom lg:mt-40 mt-20">
                  {module.button && (
                    <Link
                      to={module.button.url}
                      target={module.button.target}
                      className="button button-tan"
                    >
                      {module.button.title}
                    </Link>
                  )}
                  {module.extraButton && (
                    <Link to={module.extraButton.url} className="text-link">
                      {module.extraButton.title}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <ImageOpt
                imgData={module?.image.mediaItemUrl}
                imgAlt={module.image.altText}
                imgLoad="lazy"
                width={645}
                height={648}
                imgClass="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageContent;
export const ImageContentFragment = graphql`
  fragment ImageContentFragment on WpPage_Acfpagecontent_PageContent_ImageContent {
    # content
    id
    perHeading
    imageSize
    imagePosition
    image {
      altText
      uri
      title
      mediaItemUrl
    }
    heading
    extraClass
    description
    extraButton {
      url
      title
      target
    }
    button {
      target
      title
      url
    }
  }
`;
