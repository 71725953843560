import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageOpt from '../ImageOpt.js';

const FullImageSlider = ({ module }) => {
  const [imageCount, setimageCount] = useState(1);

  return (
    <>
      <section className="full-img-slider lg:py-60 py-30">
        {module.heading && (
          <div className="container-fluid pl-0 mPaddingRemove">
            <div className="title-brown title-line title-right">
              <h3>{module.heading}</h3>
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="full-slider bg-white lg:mt-60 mt-30">
            <Swiper
              speed={1000}
              effect={'fade'}
              onSlideChange={(e) => {
                const swiper = document.querySelector(
                  '.full-slider .swiper'
                ).swiper;
                if (swiper.activeIndex > module.fullImageSliders.length) {
                  setimageCount(
                    swiper.activeIndex - module.fullImageSliders.length
                  );
                } else if (swiper.activeIndex === 0) {
                  setimageCount(module.fullImageSliders.length);
                } else {
                  setimageCount(swiper.activeIndex);
                }
              }}
              loop={true}
              navigation={true}
              modules={[Navigation, Pagination, EffectFade]}
            >
              {module.fullImageSliders.map((item, i) => {
                return (
                  <div key={i}>
                    <SwiperSlide>
                      <div className="img">
                        {item.image && (
                          <ImageOpt
                            imgData={item.image.mediaItemUrl}
                            imgAlt={item.image.alt}
                            imgLoad="lazy"
                            width={1290}
                            height={630}
                            imgClass="object-cover h-[630px] mdscreen:h-[65vw] w-full"
                          />
                        )}
                      </div>
                      <div className="flex items-center pt-20 pl-80 mdscreen:pl-40">
                        <div className="slider-counter flex items-center">
                          <span className="text-18 text-tandark font-400 counter1">
                            {imageCount}
                          </span>
                          <span className="text-18 text-tandark font-400 counter2">
                            /
                          </span>
                          <span className="text-18 text-tandark font-400 counter3">
                            {' '}
                            {module.fullImageSliders.length}
                          </span>
                        </div>
                        {item.descriptions && (
                          <span className="inline-block text-18 pt-20 text-brown full-img-slider-text smscreen2:pr-15">
                            {item.descriptions}
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                  </div>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullImageSlider;
export const FullImageSliderFragment = graphql`
  fragment FullImageSliderFragment on WpPage_Acfpagecontent_PageContent_FullImageSlider {
    # content
    id
    heading
    extraClass
    fullImageSliders {
      descriptions
      image {
        altText
        title
        uri
        mediaItemUrl
      }
    }
  }
`;
