import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Accordionlist = ({ module, i }) => {
  return (
    <>
      <section
        className={`faq lg:py-60 py-30 ${
          module.extraClass !== "" ? module.extraClass : ""
        }`}
      >
        <div className="container m-auto">
          <div className="text-center">
            <span className="text-tan tracking-004em text-14 uppercase font-body font-400">
              {module.heading}
            </span>
          </div>
          <div className="accordian-section lg:pt-50 pt-30">
            <Accordion allowZeroExpanded={true}>
              {module.accordions.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading className="accordion">
                    <AccordionItemButton>{item.title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="fade-ani">
                    <p>{parse(item.content)}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accordionlist;
export const AccordionFragment = graphql`
  fragment AccordionFragment on WpPage_Acfpagecontent_PageContent_Accordionlist {
    # content
    heading
    extraClass
    accordions {
      title
      content
    }
  }
`;
